import React, {Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import AuthLayout from './Auth'
import MainLayout from './Main'
import {isUserLoggedIn} from "../service/Auth";

const Layouts = {
    auth: AuthLayout,
    main: MainLayout,
}

const mapStateToProps = ({user}) => ({user})

const Layout = ({user, children, location: {pathname, search}}) => {

    // Layout Rendering
    const getLayout = () => {
        if (isUserLoggedIn()) {
            return 'main';
        }
        return 'auth';

    }

    const Container = Layouts[getLayout()]

    const BootstrappedLayout = () => {
        return <Container>{children}</Container>
    }

    return (
        <Fragment>
            <Helmet titleTemplate="DSZ Admin | %s" title="DSZ Admin"/>
            {BootstrappedLayout()}
        </Fragment>
    )
}

export default withRouter(connect(mapStateToProps)(Layout))
