import {all, takeEvery, put} from 'redux-saga/effects'
import store from 'store'
import qs from 'qs'
import {history, store as reduxStore} from './../../index'
import actions from './actions'

export function* CHANGE_SETTING({payload: {setting, value}}) {
    yield store.set(`app.settings.${setting}`, value)
    yield put({
        type: 'settings/SET_STATE',
        payload: {
            [setting]: value,
        },
    })
}


export function* SETUP() {
    // load settings from url on app load
    const changeSettings = search => {
        const query = qs.parse(search, {ignoreQueryPrefix: true})
        Object.keys(query).forEach(key => {
            let value
            switch (query[key]) {
                case 'false':
                    value = false
                    break
                case 'true':
                    value = true
                    break
                default:
                    value = query[key]
                    break
            }
            reduxStore.dispatch({
                type: 'settings/CHANGE_SETTING',
                payload: {
                    setting: key,
                    value,
                },
            })
        })
    }
    yield changeSettings(history.location.search)
    yield history.listen(params => {
        const {search} = params
        changeSettings(search)
    })

}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
        SETUP(), // run once on app load to init listeners
    ])
}
