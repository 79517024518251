import React, {lazy, Suspense} from 'react'
import {Route, Switch, Redirect} from 'react-router'
import {ConnectedRouter} from 'connected-react-router'
import {connect} from 'react-redux'
import {isUserLoggedIn} from "./service/Auth";
import Layout from "./layout";

function isUser() {
    return (isUserLoggedIn() === true);
}


const routes = [
    {
        path: '/auth',
        Component: lazy(() => import('./pages/auth/index')),
        exact: true,
        privateroute: false
    },
    {
        path: '/dashboard',
        Component: lazy(() => import('./pages/dashboard/index')),
        exact: true,
        privateroute: true
    },
    {
        path: '/member',
        Component: lazy(() => import('./pages/member/index')),
        exact: true,
        privateroute: true
    },
    {
        path: '/member/create',
        Component: lazy(() => import('./pages/member/edit')),
        exact: true,
        privateroute: true
    },
    {
        path: '/member/edit/:id',
        Component: lazy(() => import('./pages/member/edit')),
        exact: true,
        privateroute: true
    },
    {
        path: '/membership',
        Component: lazy(() => import('./pages/membership/index')),
        exact: true,
        privateroute: true
    },
    {
        path: '/membership/create',
        Component: lazy(() => import('./pages/membership/edit')),
        exact: true,
        privateroute: true
    },
    {
        path: '/membership/edit/:id',
        Component: lazy(() => import('./pages/membership/edit')),
        exact: true,
        privateroute: true
    },
    {
        path: '/site',
        Component: lazy(() => import('./pages/site/index')),
        exact: true,
        privateroute: true
    },
    {
        path: '/site/edit/:id',
        Component: lazy(() => import('./pages/site/edit')),
        exact: true,
        privateroute: true
    },
    {
        path: '/site/create',
        Component: lazy(() => import('./pages/site/edit')),
        exact: true,
        privateroute: true
    },
    {
        path: '/changerequest',
        Component: lazy(() => import('./pages/changerequest/index')),
        exact: true,
        privateroute: true
    },
    {
        path: '/changerequest/edit/:id',
        Component: lazy(() => import('./pages/changerequest/edit')),
        exact: true,
        privateroute: true
    },
    {
        path: '/document',
        Component: lazy(() => import('./pages/document/index')),
        exact: true,
        privateroute: true
    },
    {
        path: '/document/create',
        Component: lazy(() => import('./pages/document/create')),
        exact: true,
        privateroute: true
    },
    {
        path: '/document/edit/:id',
        Component: lazy(() => import('./pages/document/edit')),
        exact: true,
        privateroute: true
    },
    {
        path: '/category',
        Component: lazy(() => import('./pages/category/index')),
        exact: true,
        privateroute: true
    },
    {
        path: '/category/edit/:id',
        Component: lazy(() => import('./pages/category/edit')),
        exact: true,
        privateroute: true
    }
]

const mapStateToProps = ({settings}) => ({
    routerAnimation: settings.routerAnimation,
})

const Router = ({history, routerAnimation}) => {
    return (
        <ConnectedRouter history={history}>
            <Layout>
            <Route
                render={state => {
                    const {location} = state
                    return (
                        <Switch location={location}>
                            <Route exact path="/" render={() => <Redirect to="/dashboard"/>}/>
                            {routes.map(({path, Component, exact, privateroute}) => (

                                (privateroute === true && isUser() === false)
                                    ?
                                    <Redirect to="/auth" key={'redirect_1'}/>
                                    :

                                        <Route
                                            path={path}
                                            key={path}
                                            exact={exact}
                                            render={(props) => {
                                                return (
                                                    <div className={routerAnimation}>
                                                        <Suspense fallback={null}>
                                                            <Component {...props} />
                                                        </Suspense>
                                                    </div>
                                                )
                                            }}
                                        />


                            ))}
                            <Redirect to="/auth" key={'redirect_2'} />
                        </Switch>
                    )
                }}
            />

            </Layout>
        </ConnectedRouter>
    )
}

export default connect(mapStateToProps)(Router)
