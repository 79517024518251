import React from 'react'
import {Layout} from "antd";

class Auth extends React.Component {
    render() {

        const {children} = this.props;

        return (
            <Layout>
                <Layout.Content>
                    {children}
                </Layout.Content>

            </Layout>
        )
    }
}

export default Auth