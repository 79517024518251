import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import user from './user/reducers'
import settings from './settings/reducers'
import global from './global'

const reducers = history =>
    combineReducers({
        router: connectRouter(history),
        settings,
        user,
        global
    })

export default reducers;
