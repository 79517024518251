import axios from "axios"
import {API_URL_DEV, API_URL_PRODUCTION} from "../const"
import {
    getUserToken,
    clear
} from "./Auth"

export const getApiUrl = () => {

    try {
        if (process.env.NODE_ENV === 'production') {
            if (process.env.REACT_APP_PROD_ENV === 'production') {
                return API_URL_PRODUCTION;
            } else {
                return API_URL_PRODUCTION;
            }
        }
    } catch (error) {

    }

    return API_URL_DEV

};

const instance = axios.create({
    baseURL: getApiUrl(),
    responseType: "json",
});

// request header
instance.interceptors.request.use((config) => {

    config.headers = {
        'Authorization': getUserToken(),
        'From': 'admin'
    };
    return config
}, error => {
    return Promise.reject(error)
});

instance.interceptors.response.use(function (response) {

    try {
        if (response.data.status_key === "UNAUTHORIZED_INVALID") {
            clear();
            window.location.reload();
        }
    } catch (e) {

    }

    return response;
}, function (error) {

    if (error.response.status === 500) {
        let root = document.getElementById('root');
        let notification = document.createElement('div');
        notification.addEventListener('click', () => {
            root.removeChild(notification);
        });
        notification.innerHTML = '<div class="error-notification"><div class="error-notification-inner"><table><tr><td>Es ist ein unerwarteter Fehler aufgetreten.<br>Dieser Fehler wurde automatisch an DigitalGoal weitergeleitet.<br><i>' + error.response.statusText + '</i><br><br><button class="btn btn-primary">Schließen</button></td></tr></table></div></div>';
        root.appendChild(notification);
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default instance;
