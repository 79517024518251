import localeAntd from 'antd/es/locale/en_US'
import messages from "./en-US.json";

const enLocale = {
    locale: 'en-US',
    languagename: 'English',
    localeAntd,
    messages,
}

export default enLocale;
