import localeAntd from 'antd/es/locale/de_DE'
import messages from "./de-DE.json";

const deLocale = {
    locale: 'de-DE',
    languagename: 'Deutsch',
    localeAntd,
    messages,
}

export default deLocale;
