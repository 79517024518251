const actions = {
    SET_STATE: 'user/SET_STATE',
    GET_USER: 'user/GET_USER',
}

export const updateUser = () => {
    return {
        type: actions.GET_USER
    }
}

export default actions
