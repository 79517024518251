import React, {Fragment} from 'react'
import {Button, Col, Layout, Menu, Modal, Row, Tooltip} from "antd";
import {
    BookOutlined,
    HomeOutlined, LockOutlined, PartitionOutlined,
    ReadOutlined,
    UserOutlined, UserSwitchOutlined
} from "@ant-design/icons";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import LanguageSwitcher from "../widgets/LanguageSwitcher";
import {logout} from "../service/Auth";
import {closeModal} from '../redux/global';

class Main extends React.Component {

    getLanguageDropDown = () => {
        return <Menu
            items={[{
                label: 'Deutsch',
                key: 0
            }, {
                label: 'English',
                key: 1
            }]}
        />
    }

    renderModal = () => {
        if (this.props.global.modal.open !== true) {
            return (<Fragment />)
        }
        return (
            <Modal
                title={this.props.global.modal.title}
                open={true}
                footer={null}
                width={this.props.global.modal.size}
                onCancel={() => {
                    this.props.closeModal();
                }}
            >
                {this.props.global.modal.content}
            </Modal>
        )
    }

    render() {

        const {children} = this.props;

        return (
            <Layout>
                <Layout.Header>
                    <Row gutter={24}>
                        <Col sm={20}>
                            <Menu
                                className={'main-menu'}
                                mode="horizontal"
                                items={[
                                    {
                                        key: 1,
                                        label: 'Dashboard',
                                        icon: <HomeOutlined />,
                                        onClick: () => {
                                            this.props.history.push('/dashboard');
                                        }
                                    },
                                    {
                                        key: 2,
                                        label: this.props.intl.formatMessage({ id: 'members' }),
                                        icon: <UserOutlined />,
                                        onClick: () => {
                                            this.props.history.push('/member');
                                        }
                                    },
                                    {
                                        key: 3,
                                        label: this.props.intl.formatMessage({ id: 'memberships' }),
                                        icon: <PartitionOutlined />,
                                        onClick: () => {
                                            this.props.history.push('/membership');
                                        }
                                    },
                                    {
                                        key: 4,
                                        label: this.props.intl.formatMessage({ id: 'sites' }),
                                        icon: <BookOutlined />,
                                        onClick: () => {
                                            this.props.history.push('/site');
                                        }
                                    },
                                    {
                                        key: 5,
                                        label: this.props.intl.formatMessage({ id: 'changerequests' }),
                                        icon: <UserSwitchOutlined />,
                                        onClick: () => {
                                            this.props.history.push('/changerequest');
                                        }
                                    },
                                    {
                                        key: 6,
                                        label: this.props.intl.formatMessage({ id: 'documents' }),
                                        icon: <ReadOutlined />,
                                        onClick: () => {
                                            this.props.history.push('/document');
                                        }
                                    },
                                    {
                                        key: 7,
                                        label: this.props.intl.formatMessage({ id: 'categories' }),
                                        icon: <ReadOutlined />,
                                        onClick: () => {
                                            this.props.history.push('/category');
                                        }
                                    },
                                ]}
                            />
                        </Col>
                        <Col sm={4} className="text-right">
                            <LanguageSwitcher />
                            <Tooltip title={'Logout'}>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        logout();
                                        this.props.history.push('/auth')
                                    }}
                                    className="ant-btn-danger"
                                >
                                    <LockOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>

                </Layout.Header>
                <Layout.Content>
                    <div className="cui__utils__content">{children}</div>
                    {this.renderModal()}
                </Layout.Content>

                <Layout.Footer>
                    <div style={{padding: '0.5rem', textAlign: 'center', fontSize: '0.8em'}}>
                        E-Mail: <a href="https://www.digitalgoal.de" target="_blank" rel="noreferrer">DigitalGoal Digitalagentur</a>&nbsp;|&nbsp;
                        Telefon: <a href="tel:004922166969499" target="_blank" rel="noreferrer">0221 669 69 499</a>
                    </div>
                </Layout.Footer>

            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    closeModal
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(Main)));
