import React from 'react'
import {Menu, Dropdown} from 'antd'
import {connect} from 'react-redux'

const mapStateToProps = ({settings}) => ({
    locale: settings.locale,
})

const LanguageSwitcher = ({dispatch, locale}) => {
    const changeLanguage = ({key}) => {
        dispatch({
            type: 'settings/CHANGE_SETTING',
            payload: {
                setting: 'locale',
                value: key,
            },
        })
    }
    const language = locale.substr(0, 2)
    const menu = (
        <Menu selectedKeys={[locale]} onClick={changeLanguage}>
            <Menu.Item key="en-US">
                <span className="text-uppercase font-size-12 mr-1">EN</span>
                English
            </Menu.Item>
            <Menu.Item key="de-DE">
                <span className="text-uppercase font-size-12 mr-1">DE</span>
                Deutsch
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} className="ant-btn mr-1" placement={'bottomRight'}>
            <span className="text-uppercase">{language}</span>
        </Dropdown>
    )
}

export default connect(mapStateToProps)(LanguageSwitcher)
