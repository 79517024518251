import actions from './actions'

const initialState = {
    loading: false,
    user_id: '',
    user_firstname: '',
    user_lastname: '',
    user_username: '',
    user_admin: '',
    authorized: true,
}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
            return {...state, ...action.payload}

        case actions.GET_USER:

            if (action.payload.items[0] === undefined) {
                return {
                    ...state
                }
            }

            state.user_id = action.payload.items[0].user_id;
            state.user_firstname = action.payload.items[0].user_firstname;
            state.user_lastname = action.payload.items[0].user_lastname;
            state.user_username = action.payload.items[0].user_username;
            state.user_admin = action.payload.items[0].user_admin;

            return {
                ...state
            }

        default:
            return state
    }
}
