import 'antd/dist/antd.css' // antd core styles
import './assets/style/global.scss'

import React from 'react';
import {createRoot} from 'react-dom/client';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk';
import {createHashHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'

import Localization from './localization'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'
import {getUserData, isUserLoggedIn} from "./service/Auth";

Bugsnag.start({
    apiKey: 'f28cf1c091bd0093e84b114e57c38c78',
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
        if (isUserLoggedIn()) {
            let user = getUserData();
            event.setUser(user.employeeId, user.username);
        }
    }
})

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

// middleware
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware, thunk]

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <ErrorBoundary>
        <Provider store={store}>
            <Localization>
                <Router history={history}/>
            </Localization>
        </Provider>
    </ErrorBoundary>
);


export {store, history}
