const OPEN_MODAL = 'dg/open-modal/static';
const CLOSE_MODAL = 'dg/close-modal/static';
const RERENDER_MODAL = 'dg/rerender-modal/static';

const initialState = {
    loading: false,
    modal: {
        title: null,
        content: null,
        open: false,
        size: 'lg',
        onClose: () => {},
        onEnter: () => {},
        onOpened: () => {}
    }
};

export default function content(state = initialState, action = {}) {

    switch (action.type) {

        case OPEN_MODAL:

            return {
                ...state,
                modal: {
                    title: action.title,
                    content: action.content,
                    size: action.size,
                    onClose: action.onClose,
                    onOpened: action.onOpened,
                    open: true
                }
            };

        case RERENDER_MODAL:

            return {
                ...state,
                modal: {
                    title: state.modal.title,
                    content: state.modal.content,
                    size: state.modal.size,
                    onClose: state.modal.onClose,
                    onOpened: state.modal.onOpened,
                    open: true,
                    render: (new Date())
                }
            };

        case CLOSE_MODAL:

            return {
                ...state,
                modal: initialState.modal
            };

        default:
            return state;
    }

}

export function openModal(
    title = null,
    content = null,
    size = 600,
    onClose = () => {},
    onOpened = () => {}
) {
    return {
        type: OPEN_MODAL,
        title,
        content,
        size,
        onClose,
        onOpened
    };
}

export function closeModal() {
    return {
        type: CLOSE_MODAL
    };
}

export function rerenderModal() {
    return {
        type: RERENDER_MODAL
    };
}
