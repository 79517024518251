import Api from './Api'
const APPLICATION_KEY_USER_TOKEN = 'dsz_software_application_user_token';
const APPLICATION_KEY_USER_REFRESH_TOKEN = 'dsz_software_application_user_refresh_token';
const APPLICATION_KEY_USER = 'dsz_software_application_user';

export async function login(username, password) {

    logout();

    const result = await Api.post('auth', {
        user_username: username,
        user_password: password
    });

    try {

        if (result.data.status !== 'success') {
            return false;
        }

        setUserToken(result.data.items[0].token_hash);
        setUserData(result.data.items[0]);


    } catch (error) {
        return false;
    }

    return true;

}

/**
 * @param {Object} token
 */
export function setUserToken(token) {
    localStorage.setItem(APPLICATION_KEY_USER_TOKEN, token);
}

/**
 * @param {Object} user
 */
export function setUserData(user) {
    localStorage.setItem(APPLICATION_KEY_USER, JSON.stringify(user));
}

/**
 * @param {string} token
 */
export function setRefreshToken(token) {
    localStorage.setItem(APPLICATION_KEY_USER_REFRESH_TOKEN, token);
}

/**
 * @returns {string}
 */
export function getRefreshToken() {
    return localStorage.getItem(APPLICATION_KEY_USER_REFRESH_TOKEN);
}

/**
 * @returns {Object}
 */
export function getUserData() {
    return JSON.parse(localStorage.getItem(APPLICATION_KEY_USER));
}

export function isUserLoggedIn() {

    try {
        return getUserData().member_username.length > 0
    } catch (e) {
        return false;
    }
}

export function isAdmin() {

    try {
        return getUserData().member_admin > 0;
    } catch (error) {

    }

    return false;

}

/**
 * @returns {Object}
 */
export function getUserToken() {
    return localStorage.getItem(APPLICATION_KEY_USER_TOKEN);
}

/**
 * @return {void}
 */
export function logout() {
    localStorage.removeItem(APPLICATION_KEY_USER);
    localStorage.removeItem(APPLICATION_KEY_USER_TOKEN);
    localStorage.removeItem(APPLICATION_KEY_USER_REFRESH_TOKEN);
}

/**
 * @return {void}
 */
export function clear() {
    localStorage.clear();
}
